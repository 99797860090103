import { Survey } from '@rossum/api-client/surveys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@rossum/ui/material';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { State } from '../../../types/state';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import { SmileyRating } from '../../../ui/smiley-rating/SmileyRating';
import { useAnswerDataCaptureSurvey } from '../hooks/useAnswerDataCaptureSurvey';

export type AuroraSurveyDialogProps = {
  survey: Survey;
  open: boolean;
};

export const AuroraSurveyDialog = ({
  survey,
  open,
}: AuroraSurveyDialogProps) => {
  const intl = useIntl();

  const { handleSkip, handleSubmit, increaseAuroraUsageCount } =
    useAnswerDataCaptureSurvey({
      survey,
    });

  const [rating, setRating] = useState<number | null>(null);
  const [reason, setReason] = useState<string>('');

  const isFirstStep = rating === null;

  const title = isFirstStep
    ? intl.formatMessage({
        id: 'components.surveys.dataCaptureSurvey.questions.auroraRating',
      })
    : intl.formatMessage({
        id: 'components.surveys.dataCaptureSurvey.questions.auroraRatingReason',
      });

  const hasUsedComplexLineItems = useRef(false);

  const waitingForSuggestions = useSelector(
    (state: State) => state.datapoints.waitingForSuggestions
  );

  // increment aurora count every time we are getting suggestions - at most once per annotation
  useEffect(() => {
    if (waitingForSuggestions && !hasUsedComplexLineItems.current) {
      hasUsedComplexLineItems.current = true;
      increaseAuroraUsageCount().catch(() => {});
    }
  }, [waitingForSuggestions, increaseAuroraUsageCount]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        elevation: 2,
        sx: {
          maxWidth: 450,
        },
      }}
    >
      <DialogTitle title={title} />
      {isFirstStep ? (
        <>
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 0,
            }}
          >
            <SmileyRating
              name="aurora-survey"
              sx={{
                mt: 4,
              }}
              onChange={(_e: unknown, value: number | null) => {
                if (value !== null) {
                  setRating(value);
                }
              }}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSkip}
              data-cy="survey-aurora-step-1-skip"
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.buttons.skip',
              })}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <TextField
              sx={{ width: '100%', mt: 3 }}
              multiline
              variant="outlined"
              value={reason}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setReason(e.currentTarget.value)}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3, pt: 1 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSkip}
              data-cy="survey-aurora-step-2-skip"
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.buttons.close',
              })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit([{ value: rating }, { value: reason }]);
              }}
              data-cy="survey-aurora-step-2-submit"
            >
              {intl.formatMessage({
                id: 'components.surveys.dataCaptureSurvey.buttons.send',
              })}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
