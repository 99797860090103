import { WithEtag } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Schema } from '@rossum/api-client/schemas';
import { Fade } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import { TransitionGroup } from 'react-transition-group';
import FieldsToCaptureRedesignSurvey from '../../../components/DataCaptureSurvey/FieldsToCaptureRedesignSurvey';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { Header } from '../../../ui/header/Header';
import { QueueSettingsBreadcrumbs } from '../components/QueueSettingsBreadcrumbs';
import { QueueSettingsTabs } from '../components/QueueSettingsTabs';
import { FieldPage, FieldsPage } from '../fields';
import { FieldsListSkeleton } from '../fields/components/FieldsListSkeleton';
import { FieldFormSkeleton } from '../fields/components/form/FieldFormSkeleton';
import { QueueSettingsRouteParams } from '../fields/types';
import { useFieldsHeaderMeta } from '../hooks/useFieldsHeaderMeta';
import { usePageKey } from '../hooks/usePageKey';

type FieldsRouteProps = RouteChildrenProps<QueueSettingsRouteParams> & {
  schema: WithEtag<Schema> | undefined;
  schemaIsLoading: boolean;
  queue: Queue;
};

export const FieldsRoute = ({
  match,
  location,
  schema,
  schemaIsLoading,
  queue,
}: FieldsRouteProps) => {
  const intl = useIntl();

  const { subPageKeys } = usePageKey();

  const { breadcrumbs, title, description, buttons, onBackButtonClicked } =
    useFieldsHeaderMeta(`${match?.url ?? ''}/fields`, subPageKeys, schema);

  if (!match) {
    return null;
  }

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          tabs={
            subPageKeys.length === 0 ? (
              <QueueSettingsTabs value="fields" parentPath={match.url} />
            ) : null
          }
          onBackButtonClicked={onBackButtonClicked}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              breadcrumbs={[
                {
                  label: intl.formatMessage({
                    id: 'features.queueSettings.fields.title',
                  }),
                  to: `${match.url}/fields`,
                },
                ...breadcrumbs,
              ]}
              queueName={queue.name}
              settingsPath={match.url}
            />
          }
          buttons={buttons}
          title={title}
          description={description}
        />
      )}
    >
      <TransitionGroup>
        <Fade key={location.pathname} appear unmountOnExit>
          <div>
            <Switch location={location}>
              <Route path={`${match.path}/fields`} exact>
                {/* TODO: The inferred type here is wrong, maybe a newer version of router fixed it? */}
                {routeChildrenProps =>
                  schemaIsLoading || !schema ? (
                    <FieldsListSkeleton />
                  ) : (
                    <FieldsPage {...routeChildrenProps} schema={schema} />
                  )
                }
              </Route>
              <Route
                path={`${match.path}/fields/:sectionId?/:schemaItemId?/:schemaItemChildId?`}
                exact
              >
                {routeChildrenProps =>
                  schemaIsLoading || !schema ? (
                    <FieldFormSkeleton />
                  ) : (
                    <FieldPage
                      {...routeChildrenProps}
                      schema={schema}
                      queue={queue}
                    />
                  )
                }
              </Route>
              <Route path={`${match.path}`}>
                <Redirect
                  to={{
                    pathname: `${match.url}/fields`,
                    state: location.state,
                  }}
                />
              </Route>
            </Switch>
          </div>
        </Fade>
      </TransitionGroup>
      <FieldsToCaptureRedesignSurvey />
    </PageLayoutV2>
  );
};
