import { useIntl } from 'react-intl';
import {
  Breadcrumb,
  Breadcrumbs,
} from '../../../components/BreadcrumbsV2/Breadcrumbs';

type QueueSettingsBreadcrumbsProps = {
  queueName: string;
  settingsPath: string;
  breadcrumbs: Breadcrumb[];
};

export const QueueSettingsBreadcrumbs = ({
  queueName,
  settingsPath,
  breadcrumbs,
}: QueueSettingsBreadcrumbsProps) => {
  const intl = useIntl();

  const QueueSettingsBreadcrumbs = [
    {
      label: intl.formatMessage({
        id: 'features.queueSettings.breadcrumbs.queues',
      }),
    },
    { label: queueName },
    {
      label: intl.formatMessage({
        id: 'features.queueSettings.breadcrumbs.settings',
      }),
      to: settingsPath,
    },
    ...breadcrumbs,
  ];

  return <Breadcrumbs breadcrumbs={QueueSettingsBreadcrumbs} />;
};
